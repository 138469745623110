import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    postClient: builder.mutation({
      query: (body) => ({
        url: `client`,
        method: 'POST',
        body,
      }),
    }),
    getClientById: builder.mutation({
      query: (id) => ({
        url: `client/${id}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getClient: builder.mutation({
      query: (params) => ({
        url: `client`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getAllClient: builder.mutation({
      query: () => ({
        url: `client/all`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    updateInlineProspectiveClient: builder.mutation({
      query: ({ body, client_id }) => ({
        url: `client/prospectiveclient/inline/${client_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    updateInlineClient: builder.mutation({
      query: ({ body, client_id }) => ({
        url: `client/inline/${client_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    updateClient: builder.mutation({
      query: ({ body, client_id }) => ({
        url: `client/${client_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getClientStatus: builder.mutation({
      query: (params) => ({
        url: `client/clientstatus`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    createClientStatus: builder.mutation({
      query: (body) => ({
        url: `client/clientstatus`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getProspectiveClientList: builder.mutation({
      query: (params) => ({
        url: `client/prospectiveclient`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getProspectiveMetrics: builder.mutation({
      query: (params) => ({
        url: `client/prospectiveclient/metrics`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getClientMetrics: builder.mutation({
      query: (params) => ({
        url: `project/dashboard/client/metrices`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getAssignedCpms: builder.mutation({
      query: (params) => ({
        url: `client/assignedcpms`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    getBillsOfClient: builder.mutation({
      query: (params) => ({
        url: `client/bills`,
        method: 'GET',
        params,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    updateClientMailConfig: builder.mutation({
      query: ({ body, client_id }) => ({
        url: `client/mailConfig/${client_id}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
    clientLoginAccess: builder.mutation({
      query: (body) => ({
        url: `client/clientLoginAccess`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) { }
      },
    }),
  }),
});

export const {
  useGetClientMutation,
  useGetClientByIdMutation,
  useGetAllClientMutation,
  usePostClientMutation,
  useUpdateClientMutation,
  useGetClientStatusMutation,
  useCreateClientStatusMutation,
  useGetProspectiveClientListMutation,
  useGetProspectiveMetricsMutation,
  useGetClientMetricsMutation,
  useUpdateInlineClientMutation,
  useUpdateInlineProspectiveClientMutation,
  useGetAssignedCpmsMutation,
  useGetBillsOfClientMutation,
  useUpdateClientMailConfigMutation,
  useClientLoginAccessMutation,
} = clientApi;
